@import "https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap";
@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";
:root {
  --primary-dark: #242424;
  --primary-light: #f2e9e4;
  --sketch-light: #7f797b;
  --sketch-dark: #7f797b;
  --text-bg: #c6bbbe;
  --danger: #c0353c;
  --padding-xs: 5px;
  --padding-sm: 10px;
  --padding-md: 25px;
  --padding-lg: 50px;
  --padding-xl: 100px;
  --margin-sm: 10px;
  --margin-md: 20px;
  --margin-lg: 50px;
  --margin-xl: 80px;
  --gap-xs: 5px;
  --gap-sm: 10px;
  --gap-md: 25px;
  --gap-lg: 50px;
  --border-radius: 5px;
  --border-radius-accent: 50px;
  --container-max-width: 1200px;
  --content-max-width: 900px;
  --sketch-animation-duration: .7s;
  --font-primary: "Abril Fatface";
  --font-secondary: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}

::selection {
  color: var(--primary-light);
  background: var(--primary-dark);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body, #root {
  height: 100%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

br {
  content: "";
  margin: 15px 0;
  display: block;
}

svg {
  fill: currentColor;
}

.xFpZHq_skillsContainer {
  margin-top: var(--margin-xl);
  width: 100%;
  gap: var(--gap-lg);
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.xFpZHq_imageContainer {
  max-height: 100%;
  max-width: 300px;
  width: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.xFpZHq_aboutImage {
  width: 100%;
  height: auto;
}

.xFpZHq_skillsTextContent {
  width: 50%;
  max-width: 450px;
  gap: var(--gap-md);
  flex-direction: column;
  display: flex;
}

.xFpZHq_highVoltageSketch {
  width: 100px;
  height: 100px;
  background-color: var(--sketch-dark);
  top: -80px;
  right: -70px;
  rotate: 40deg;
  -webkit-mask-image: url("high-voltage.0848b3cd.svg");
  mask-image: url("high-voltage.0848b3cd.svg");
}

.xFpZHq_sparkleClusterSketch {
  width: 100px;
  height: 100px;
  top: -60px;
  left: -25px;
  transform: rotateY(180deg);
}

.xFpZHq_animatedRocketSketch {
  width: 150px;
  height: 150px;
  background-color: var(--sketch-dark);
  animation-name: xFpZHq_animateRocket;
  top: -100px;
  right: -175px;
  transform: rotateZ(30deg);
  -webkit-mask-image: url("rocket-1.41fb43e3.svg");
  mask-image: url("rocket-1.41fb43e3.svg");
}

@keyframes xFpZHq_animateRocket {
  0% {
    -webkit-mask-image: url("rocket-1.41fb43e3.svg");
    mask-image: url("rocket-1.41fb43e3.svg");
  }

  33% {
    -webkit-mask-image: url("rocket-2.ff674419.svg");
    mask-image: url("rocket-2.ff674419.svg");
  }

  66% {
    -webkit-mask-image: url("rocket-3.1e89e07f.svg");
    mask-image: url("rocket-3.1e89e07f.svg");
  }

  100% {
    -webkit-mask-image: url("rocket-1.41fb43e3.svg");
    mask-image: url("rocket-1.41fb43e3.svg");
  }
}

@media (max-width: 700px) {
  .xFpZHq_skillsContainer {
    flex-direction: column;
    align-items: center;
  }

  .xFpZHq_skillsTextContent {
    gap: var(--gap-md);
    width: 100%;
  }

  .xFpZHq_imageContainer {
    width: 100%;
  }

  .xFpZHq_sparkleClusterSketch {
    width: 70px;
    height: 70px;
  }

  .xFpZHq_animatedRocketSketch {
    width: 100px;
    height: 100px;
    top: -50px;
    right: -120px;
  }
}

.sgUlHG_frame {
  border-radius: var(--border-radius);
  border: 1px solid var(--primary-dark);
}

.sgUlHG_frameRight {
  border-top-right-radius: var(--border-radius-accent);
  box-shadow: -10px 10px 0px var(--primary-dark);
}

.sgUlHG_frameLeft {
  border-top-left-radius: var(--border-radius-accent);
  box-shadow: 10px 10px 0px var(--primary-dark);
}

.sgUlHG_sketchHelper {
  position: relative;
}

.sgUlHG_sketchAnimation {
  animation-duration: var(--sketch-animation-duration);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

._YO1bW_titleText {
  font-family: var(--font-primary);
  text-transform: uppercase;
  color: var(--primary-dark);
  margin: var(--margin-md) 0;
  font-size: 12vw;
  font-weight: 400;
}

._YO1bW_heroTitleText {
  font-family: var(--font-primary);
  text-transform: uppercase;
  color: var(--primary-light);
  margin: var(--margin-md) 0;
  font-size: 12vw;
  font-weight: 400;
}

._YO1bW_heroTitleText::selection {
  color: var(--primary-dark);
  background: var(--primary-light);
}

._YO1bW_heroNormalText {
  font-family: var(--font-secondary);
  color: var(--primary-light);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 35px;
}

._YO1bW_heroNormalText::selection {
  color: var(--primary-dark);
  background: var(--primary-light);
}

._YO1bW_normalText {
  font-family: var(--font-secondary);
  color: var(--primary-dark);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 25px;
}

._YO1bW_smallText {
  font-family: var(--font-secondary);
  color: var(--primary-dark);
  font-size: 1rem;
  font-weight: 400;
}

._YO1bW_subtitleText {
  font-family: var(--font-primary);
  text-transform: uppercase;
  color: var(--primary-dark);
  margin-bottom: var(--margin-sm);
  font-size: 2rem;
  font-weight: 400;
}

._YO1bW_sectionText {
  width: 90%;
  max-width: var(--content-max-width);
  display: block;
}

._YO1bW_primaryLink {
  font-family: var(--font-secondary);
  text-transform: uppercase;
  color: var(--primary-light);
  font-size: 1rem;
  font-weight: 400;
  transition: all .3s ease-in-out;
}

._YO1bW_tagText {
  font-family: var(--font-secondary);
  color: var(--primary-dark);
  font-size: 1.3rem;
  font-weight: 400;
}

._YO1bW_primaryButtonText {
  font-family: var(--font-primary);
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 400;
}

._YO1bW_headerInitialsText {
  font-family: var(--font-primary);
  color: var(--primary-light);
  font-size: 1.2rem;
  font-weight: 400;
}

._YO1bW_inputLabelText {
  font-family: var(--font-secondary);
  color: var(--primary-dark);
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 400;
}

._YO1bW_inputText {
  font-family: var(--font-secondary);
  color: var(--primary-dark);
  font-size: 1.3rem;
  font-weight: 400;
}

._YO1bW_footerText {
  font-family: var(--font-secondary);
  color: var(--primary-light);
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
}

._YO1bW_footerText::selection {
  color: var(--primary-dark);
  background: var(--primary-light);
}

@media (hover: hover) {
  ._YO1bW_primaryLink:hover {
    font-style: italic;
  }
}

@media (min-width: 1100px) {
  ._YO1bW_heroTitleText, ._YO1bW_titleText {
    font-size: 9rem;
  }
}

@media (max-width: 480px) {
  ._YO1bW_heroTitleText {
    font-size: 17vw;
  }

  ._YO1bW_titleText {
    font-size: 3rem;
  }

  ._YO1bW_subtitleText {
    font-size: 1.8rem;
  }

  ._YO1bW_heroNormalText {
    font-size: 1.2rem;
  }

  ._YO1bW_normalText {
    font-size: 1rem;
  }

  ._YO1bW_sectionText {
    width: 95%;
  }

  ._YO1bW_primaryButtonText {
    font-size: 1.2rem;
  }
}

._4T0KxW_link {
  color: var(--primary-dark);
  text-decoration: underline;
  transition: all .3s ease-in-out;
}

@media (hover: hover) {
  ._4T0KxW_link:hover, ._4T0KxW_link:focus-visible {
    text-decoration-line: underline;
    text-decoration-style: double;
  }
}

.tkfPkq_sectionWrapper {
  padding: var(--padding-md) var(--padding-lg);
  margin: var(--margin-lg) 0;
  min-height: 50vh;
  width: 100%;
  max-width: var(--container-max-width);
}

@media (max-width: 480px) {
  .tkfPkq_sectionWrapper {
    padding: var(--padding-md);
    margin: var(--margin-lg) 0;
    padding-top: var(--padding-lg);
  }
}

.irsLvq_skillTagContent {
  border-radius: var(--border-radius);
  padding: var(--padding-xs) var(--padding-sm);
  background-color: var(--text-bg);
  box-shadow: 5px 5px 0px var(--primary-dark);
  transition: all .3s ease-in-out;
}

.irsLvq_tagsContainer {
  gap: var(--gap-sm);
  flex-wrap: wrap;
  display: flex;
}

@media (hover: hover) {
  .irsLvq_skillTagContent:hover {
    cursor: default;
    color: var(--text-bg);
    background-color: var(--primary-dark);
    box-shadow: none;
  }
}

.gsLUHG_sketch {
  z-index: 4;
  position: absolute;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

._7o_BfG_animatedSparkleCluster {
  background-color: var(--sketch-dark);
  animation-name: _7o_BfG_sparkle;
  -webkit-mask-image: url("sparkle-cluster-1.f6338ee5.svg");
  mask-image: url("sparkle-cluster-1.f6338ee5.svg");
}

@keyframes _7o_BfG_sparkle {
  0% {
    -webkit-mask-image: url("sparkle-cluster-1.f6338ee5.svg");
    mask-image: url("sparkle-cluster-1.f6338ee5.svg");
  }

  33% {
    -webkit-mask-image: url("sparkle-cluster-2.3696722c.svg");
    mask-image: url("sparkle-cluster-2.3696722c.svg");
  }

  66% {
    -webkit-mask-image: url("sparkle-cluster-3.28be702a.svg");
    mask-image: url("sparkle-cluster-3.28be702a.svg");
  }

  100% {
    -webkit-mask-image: url("sparkle-cluster-1.f6338ee5.svg");
    mask-image: url("sparkle-cluster-1.f6338ee5.svg");
  }
}

.C0srJW_formContainer {
  height: fit-content;
  width: 100%;
  padding: var(--padding-lg) var(--padding-sm);
  justify-content: center;
  align-items: center;
  display: flex;
}

.C0srJW_formContent {
  width: 100%;
  max-width: var(--content-max-width);
  min-height: 485px;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: var(--gap-md);
  background-color: var(--primary-light);
  padding: var(--padding-md);
  flex-direction: column;
  display: flex;
}

.C0srJW_senderInfoContainer {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: var(--gap-md);
  display: flex;
}

.C0srJW_formSubmitButton {
  width: 50%;
  min-width: 150px;
  max-width: 230px;
  min-height: 45px;
  margin: 0;
  position: relative;
  border: 1px solid var(--primary-dark) !important;
  background-color: var(--primary-light) !important;
  color: var(--primary-dark) !important;
  box-shadow: 5px 5px 0px var(--primary-dark) !important;
}

.C0srJW_animatedPaperAirplaneSketch {
  width: 200px;
  height: 200px;
  background-color: var(--sketch-dark);
  animation-name: C0srJW_animatePaperAirplane;
  top: -40px;
  right: -240px;
  -webkit-mask-image: url("paper-airplane-1.d924f408.svg");
  mask-image: url("paper-airplane-1.d924f408.svg");
}

@keyframes C0srJW_animatePaperAirplane {
  0% {
    -webkit-mask-image: url("paper-airplane-1.d924f408.svg");
    mask-image: url("paper-airplane-1.d924f408.svg");
  }

  33% {
    -webkit-mask-image: url("paper-airplane-2.ece3d917.svg");
    mask-image: url("paper-airplane-2.ece3d917.svg");
  }

  66% {
    -webkit-mask-image: url("paper-airplane-3.0ecefbcb.svg");
    mask-image: url("paper-airplane-3.0ecefbcb.svg");
  }

  100% {
    -webkit-mask-image: url("paper-airplane-1.d924f408.svg");
    mask-image: url("paper-airplane-1.d924f408.svg");
  }
}

.C0srJW_sparkleClusterSketch {
  width: 100px;
  height: 100px;
  top: -60px;
  left: -25px;
  transform: rotateY(180deg);
}

@media (hover: hover) {
  .C0srJW_formSubmitButton:hover, .C0srJW_formSubmitButton:focus-visible {
    background-color: var(--primary-dark) !important;
    color: var(--primary-light) !important;
    box-shadow: none !important;
  }
}

@media (max-width: 700px) {
  .C0srJW_senderInfoContainer {
    flex-direction: column;
  }

  .C0srJW_animatedPaperAirplaneSketch {
    width: 150px;
    height: 150px;
    top: -105px;
    right: -130px;
  }

  .C0srJW_sparkleClusterSketch {
    width: 70px;
    height: 70px;
  }
}

.EuC9lG_button {
  border-radius: var(--border-radius);
  padding: var(--padding-xs) var(--padding-sm);
  margin: var(--margin-md) 0;
  width: fit-content;
  height: fit-content;
  transition: all .3s ease-in-out;
  display: block;
}

.EuC9lG_button:disabled {
  pointer-events: none !important;
}

.EuC9lG_buttonLight {
  border: 2px solid var(--primary-light);
  background-color: var(--primary-light);
  color: var(--primary-dark);
}

.EuC9lG_buttonDark {
  border: 2px solid var(--primary-dark);
  background-color: var(--primary-dark);
  color: var(--primary-light);
}

@media (hover: hover) {
  .EuC9lG_button:hover {
    cursor: pointer;
  }

  .EuC9lG_buttonLight:hover, .EuC9lG_buttonLight:focus-visible {
    background-color: var(--primary-dark);
    color: var(--primary-light);
    box-shadow: 5px 5px 0px var(--primary-light);
  }

  .EuC9lG_buttonDark:hover, .EuC9lG_buttonDark:focus-visible {
    background-color: var(--primary-light);
    color: var(--primary-dark);
    box-shadow: 5px 5px 0px var(--primary-dark);
  }
}

._5KBhJq_generalInputContainer {
  width: 100%;
  min-height: 100px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

._5KBhJq_generalInputContent {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

._5KBhJq_validationErrorText, ._5KBhJq_labelError {
  color: var(--danger) !important;
}

._5KBhJq_input {
  height: 50px;
  background-color: var(--text-bg);
  border: 1px solid var(--primary-dark);
  border-radius: var(--border-radius);
  padding: var(--padding-xs);
}

._5KBhJq_input:focus {
  outline: none;
}

._5KBhJq_textArea {
  resize: none;
  height: 120px;
}

._5KBhJq_inputError {
  border: 1px solid var(--danger) !important;
}

.cIp7bq_footerContainer {
  background-color: var(--primary-dark);
  color: var(--primary-light);
  min-height: 20vh;
  justify-content: space-between;
  align-items: center;
  gap: var(--gap-md);
  padding: var(--padding-lg) var(--padding-md);
  padding-bottom: var(--padding-md);
  width: 100%;
  box-shadow: 0 -1px 15px var(--primary-dark);
  flex-direction: column;
  display: flex;
}

.cIp7bq_footerIconsContainer {
  justify-content: space-around;
  align-items: center;
  gap: var(--gap-md);
  max-width: 200px;
  width: 100%;
  max-height: 200px;
  height: 100%;
  margin-bottom: var(--margin-md);
  display: flex;
}

.cIp7bq_footerIcon {
  width: 30px;
  height: 30px;
  color: var(--primary-light);
  transition: all .2s ease-in-out;
}

@media (hover: hover) {
  .cIp7bq_footerIcon:hover {
    color: var(--text-bg);
    transform: scale(1.1);
  }
}

@media (max-width: 480px) {
  .cIp7bq_footerIconsContainer {
    flex-direction: column;
  }
}

.UOHutW_headerContainer {
  background-color: var(--primary-dark);
  width: 100%;
  height: 60px;
  box-shadow: 0 1px 15px var(--primary-dark);
  z-index: 5;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
}

.UOHutW_headerContent {
  width: 100%;
  max-width: var(--container-max-width);
  height: 100%;
  padding: var(--padding-sm) var(--padding-md);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.UOHutW_navigationContainer {
  justify-content: center;
  align-items: center;
  display: flex;
}

.UOHutW_navigationLinksContainer {
  justify-content: center;
  align-items: center;
  gap: var(--gap-md);
  display: flex;
}

.UOHutW_headerButton {
  margin: 0 !important;
  font-size: 1.1rem !important;
}

.UOHutW_hamburgerButton, .UOHutW_hamburgerIcon {
  display: none;
}

.UOHutW_mobileHeaderButton {
  display: none !important;
}

@media (hover: hover) {
  .UOHutW_hamburgerButton:hover {
    cursor: pointer;
  }
}

@media (max-width: 700px) {
  .UOHutW_hamburgerButton {
    background-color: #0000;
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .UOHutW_hamburgerIcon {
    width: 30px;
    height: 30px;
    fill: var(--primary-light);
    display: flex;
  }

  .UOHutW_navigationContainer {
    display: none;
  }

  .UOHutW_desktopHeaderButton {
    display: none !important;
  }

  .UOHutW_mobileHeaderButton {
    display: block !important;
  }

  .UOHutW_navigationLinksContainer {
    flex-direction: column;
  }

  .UOHutW_mobileNavigationContainer {
    border-top: 1px solid var(--primary-light);
    border-bottom: 1px solid var(--primary-light);
    background-color: var(--primary-dark);
    gap: var(--gap-md);
    padding: var(--padding-md);
    flex-direction: column;
    display: flex;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
  }
}

.ZPN_sW_projectSummariesContainer {
  margin-top: var(--margin-lg);
  align-items: center;
  gap: var(--gap-lg);
  width: 100%;
  flex-direction: column;
  display: flex;
}

.ZPN_sW_rotatingStarSketchLarge {
  width: 100px;
  height: 100px;
  background-color: var(--sketch-dark);
  top: -110px;
  right: -35px;
  -webkit-mask-image: url("star-3.ad4e5a48.svg");
  mask-image: url("star-3.ad4e5a48.svg");
}

.ZPN_sW_rotatingStarSketchSmall {
  width: 50px;
  height: 50px;
  background-color: var(--sketch-dark);
  top: -20px;
  right: -55px;
  -webkit-mask-image: url("star-2.ac5f89b6.svg");
  mask-image: url("star-2.ac5f89b6.svg");
}

.ZPN_sW_arrowSketch {
  width: 100px;
  height: 100px;
  background-color: var(--sketch-dark);
  pointer-events: none;
  top: 15px;
  right: -100px;
  -webkit-mask-image: url("arrow-1.1134c380.svg");
  mask-image: url("arrow-1.1134c380.svg");
}

@media (max-width: 480px) {
  .ZPN_sW_arrowSketch {
    width: 50px;
    height: 50px;
    top: 25px;
    right: -45px;
  }
}

.QR3xhG_projectCardContainer {
  width: 80%;
  min-width: 500px;
  max-width: var(--content-max-width);
  min-height: 320px;
  background-color: var(--primary-light);
  box-shadow: 0 0 10px var(--text-bg);
  border-radius: var(--border-radius);
  display: flex;
  position: relative;
}

.QR3xhG_projectName {
  width: 50px;
  padding: var(--padding-xs);
  background-color: var(--primary-dark);
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  justify-content: center;
  align-items: center;
  display: flex;
}

.QR3xhG_name {
  color: var(--primary-light);
  letter-spacing: 10px;
  text-indent: .5em;
  transform: rotate(-90deg);
}

.QR3xhG_projectBody {
  width: 100%;
  background-color: var(--primary-light);
  gap: var(--gap-xs);
  border-radius: var(--border-radius);
  flex-direction: column;
  display: flex;
  position: relative;
}

.QR3xhG_projectDescription {
  width: 60%;
  height: 100%;
  padding: var(--padding-sm);
  padding-left: var(--padding-md);
  justify-content: space-around;
  gap: var(--gap-sm);
  flex-direction: column;
  transition: all .5s ease-in-out;
  display: flex;
}

.QR3xhG_projectImage {
  width: 40%;
  clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
  opacity: .8;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  background-position: center;
  background-size: cover;
  transition: all .5s ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.QR3xhG_buttonContainer {
  height: 0;
  background-color: #0000;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.QR3xhG_button {
  border: 1px solid var(--primary-dark);
  background-color: var(--primary-light);
  color: var(--primary-dark);
  opacity: 0;
}

.QR3xhG_iconContainer {
  height: 40px;
  width: 40px;
  background-color: var(--primary-dark);
  border-radius: var(--border-radius);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.QR3xhG_openInNewTabIcon {
  height: 30px;
  width: 30px;
  color: var(--primary-light);
}

@keyframes QR3xhG_btnReveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (hover: hover) {
  .QR3xhG_projectCardContainer:hover {
    cursor: pointer;
  }

  .QR3xhG_projectCardContainer:hover > .QR3xhG_buttonContainer {
    height: 100%;
  }

  .QR3xhG_projectCardContainer:hover .QR3xhG_button {
    animation-name: QR3xhG_btnReveal;
    animation-duration: .5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    box-shadow: none !important;
  }

  .QR3xhG_projectCardContainer:hover .QR3xhG_projectImage {
    width: 85%;
    opacity: 1;
  }

  .QR3xhG_projectCardContainer:hover .QR3xhG_projectDescription {
    opacity: .2;
  }
}

@media (max-width: 700px) {
  .QR3xhG_projectCardContainer {
    width: 90%;
    min-width: 265px;
    max-width: 300px;
    min-height: 600px;
    flex-direction: column;
  }

  .QR3xhG_projectName {
    width: 100%;
    border-top-right-radius: var(--border-radius);
    height: 50px;
    border-bottom-left-radius: 0;
  }

  .QR3xhG_name {
    transform: rotate(0);
  }

  .QR3xhG_projectBody {
    height: 100%;
    flex-grow: 1;
  }

  .QR3xhG_projectDescription {
    width: 100%;
    height: fit-content;
    max-height: 70%;
    padding: var(--padding-md);
  }

  .QR3xhG_projectImage {
    width: 100%;
    clip-path: polygon(0% 25%, 100% 0%, 100% 100%, 0% 100%);
    opacity: .8;
    border-top-right-radius: 0;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    transition: all .5s ease-in-out;
    inset: 70% 0 0;
  }

  @media (hover: hover) {
    .QR3xhG_projectCardContainer:hover .QR3xhG_projectImage {
      width: 100%;
      top: 20%;
    }
  }
}

@media (max-width: 480px) {
  .QR3xhG_projectCardContainer {
    min-height: 620px;
  }
}

.jJJ1Pq_rotatingStarSketchMedium {
  width: 80px;
  height: 80px;
  background-color: var(--sketch-light);
  pointer-events: none;
  top: 35px;
  right: -65px;
  -webkit-mask-image: url("star-1.b757eb39.svg");
  mask-image: url("star-1.b757eb39.svg");
}

.jJJ1Pq_rotatingStarSketchSmall {
  width: 50px;
  height: 50px;
  background-color: var(--sketch-light);
  pointer-events: none;
  top: 95px;
  right: 15px;
  -webkit-mask-image: url("star-2.ac5f89b6.svg");
  mask-image: url("star-2.ac5f89b6.svg");
}

.jJJ1Pq_sparkleClusterSketch {
  width: 100px;
  height: 100px;
  top: -55px;
  right: -55px;
}

@media (max-width: 480px) {
  .jJJ1Pq_sparkleClusterSketch {
    top: -60px;
    right: 0;
  }
}

.Kc_tXG_heroWrapper * {
  z-index: 3;
}

.Kc_tXG_heroWrapper {
  color: var(--primary-light);
  min-height: 100vh;
  padding: var(--padding-lg);
  padding-top: var(--padding-xl);
  width: 100%;
  height: 100%;
  background-image: url("background-hero.c788db06.svg");
  background-repeat: repeat;
  background-color: var(--primary-dark);
  min-height: 100vh;
  width: 100%;
  margin-bottom: var(--margin-lg);
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@supports (-webkit-touch-callout: none) {
  .Kc_tXG_heroWrapper {
    min-height: var(--hero-height);
  }
}

.Kc_tXG_heroContent {
  gap: var(--gap-xs);
  height: 100%;
  width: 100%;
  max-width: var(--container-max-width);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.Kc_tXG_iconContainer {
  height: fit-content;
  justify-content: center;
  align-items: center;
  animation-name: Kc_tXG_arrowLoop;
  animation-duration: 2.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  display: flex;
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
}

@keyframes Kc_tXG_arrowLoop {
  0% {
    bottom: 25px;
  }

  50% {
    bottom: 50px;
  }

  100% {
    bottom: 25px;
  }
}

.Kc_tXG_icon {
  width: 40px;
  height: 40px;
  fill: var(--primary-light);
}

.Kc_tXG_heroWrapperChildrenContainer {
  height: fit-content;
  width: 100%;
}

.Kc_tXG_heroTitleAnimation {
  animation-name: Kc_tXG_heroAnimation;
  animation-duration: .5s;
  animation-timing-function: ease-in-out;
}

.Kc_tXG_heroAnimation {
  opacity: 0;
  animation-name: Kc_tXG_heroAnimation;
  animation-duration: .5s;
  animation-timing-function: ease-in-out;
  animation-delay: .2s;
  animation-fill-mode: forwards;
}

@keyframes Kc_tXG_heroAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (hover: hover) {
  .Kc_tXG_arrowLink:hover {
    cursor: pointer;
  }
}

@media (min-width: 1100px) {
  .Kc_tXG_heroWrapper {
    gap: var(--gap-sm);
  }
}

@media (min-width: 1400px) {
  .Kc_tXG_icon {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 480px) {
  .Kc_tXG_heroWrapper {
    padding: var(--padding-lg) var(--padding-md);
  }
}

.MVUxyq_pageWrapper {
  background-image: url("background-page.a8dbfb0d.svg");
  background-repeat: repeat;
  background-color: var(--primary-light);
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.oNBnCa_projectSectionContainer {
  justify-content: center;
  align-items: center;
  gap: var(--gap-lg);
  flex-direction: column;
  display: flex;
}

.oNBnCa_projectDetailsContainer {
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: flex-start;
  gap: var(--gap-lg);
  display: flex;
}

.oNBnCa_projectDetailsContent {
  width: 60%;
  gap: var(--gap-md);
  flex-direction: column;
  display: flex;
}

.oNBnCa_mobileScreenshotContainer {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.oNBnCa_mobileScreenshotContent {
  width: 100%;
  height: auto;
  max-height: 500px;
  overflow: hidden;
}

.oNBnCa_projectLinksContainer {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: var(--gap-md);
  display: flex;
}

.oNBnCa_projectLink {
  margin: 0;
}

.oNBnCa_desktopScreenshotContainer {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.oNBnCa_desktopScreenshotContent {
  width: 100%;
  height: auto;
  max-width: var(--content-max-width);
}

.oNBnCa_screenshotPreviewsContainer {
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: var(--gap-sm);
  display: flex;
}

.oNBnCa_screenshotPreviewsContainer button {
  background-color: #0000;
  border: none;
}

.oNBnCa_screenshotPreviewContent {
  height: 100px;
  width: auto;
}

@media (hover: hover) {
  .oNBnCa_screenshotPreviewsContainer button:hover {
    cursor: pointer;
  }
}

@media (max-width: 700px) {
  .oNBnCa_projectDetailsContainer {
    flex-direction: column;
    align-items: center;
  }

  .oNBnCa_projectDetailsContent {
    width: 100%;
  }

  .oNBnCa_screenshotPreviewContent {
    height: 70px;
  }
}

@media (max-width: 480px) {
  .oNBnCa_projectLinksContainer {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .oNBnCa_mobileScreenshotContent {
    max-height: fit-content;
  }

  .oNBnCa_mobileScreenshotContainer, .oNBnCa_desktopScreenshotContainer {
    padding: 0 var(--padding-sm);
  }

  .oNBnCa_screenshotPreviewContent {
    height: 50px;
  }
}

.XksHxW_animatedAlienShipSketch {
  width: 150px;
  height: 150px;
  background-color: var(--sketch-dark);
  animation-name: XksHxW_animateAlienShip;
  top: -65px;
  right: -90px;
  -webkit-mask-image: url("alien-ship-1.1afbb720.svg");
  mask-image: url("alien-ship-1.1afbb720.svg");
}

@keyframes XksHxW_animateAlienShip {
  0% {
    -webkit-mask-image: url("alien-ship-1.1afbb720.svg");
    mask-image: url("alien-ship-1.1afbb720.svg");
  }

  33% {
    -webkit-mask-image: url("alien-ship-2.a92e3e2c.svg");
    mask-image: url("alien-ship-2.a92e3e2c.svg");
  }

  66% {
    -webkit-mask-image: url("alien-ship-3.97dc4cd5.svg");
    mask-image: url("alien-ship-3.97dc4cd5.svg");
  }

  100% {
    -webkit-mask-image: url("alien-ship-1.1afbb720.svg");
    mask-image: url("alien-ship-1.1afbb720.svg");
  }
}

@media (max-width: 480px) {
  .XksHxW_animatedAlienShipSketch {
    top: -95px;
    right: -90px;
  }
}

.GxAwDq_rotatingStarSketchMedium {
  width: 80px;
  height: 80px;
  background-color: var(--sketch-dark);
  top: -110px;
  right: 5px;
  -webkit-mask-image: url("star-1.b757eb39.svg");
  mask-image: url("star-1.b757eb39.svg");
}

.GxAwDq_rotatingStarSketchSmall {
  width: 50px;
  height: 50px;
  background-color: var(--sketch-dark);
  top: -45px;
  right: -50px;
  -webkit-mask-image: url("star-2.ac5f89b6.svg");
  mask-image: url("star-2.ac5f89b6.svg");
}

@media (max-width: 700px) {
  .GxAwDq_rotatingStarSketchMedium {
    top: -110px;
    right: -50px;
  }

  .GxAwDq_rotatingStarSketchSmall {
    top: -45px;
    right: -80px;
  }
}

@media (max-width: 480px) {
  .GxAwDq_rotatingStarSketchMedium {
    top: -110px;
    right: 45px;
  }

  .GxAwDq_rotatingStarSketchSmall {
    top: -60px;
    right: -15px;
  }
}

.QCPe8W_sparkleClusterSketch {
  width: 100px;
  height: 100px;
  top: -60px;
  left: -25px;
  transform: rotateY(180deg);
}

@media (max-width: 700px) {
  .QCPe8W_sparkleClusterSketch {
    width: 70px;
    height: 70px;
  }
}

.zFMoKW_rotatingStarSketchMedium {
  width: 80px;
  height: 80px;
  background-color: var(--sketch-light);
  pointer-events: none;
  top: 75px;
  right: -65px;
  -webkit-mask-image: url("star-1.b757eb39.svg");
  mask-image: url("star-1.b757eb39.svg");
}

.zFMoKW_rotatingStarSketchSmall {
  width: 50px;
  height: 50px;
  background-color: var(--sketch-light);
  pointer-events: none;
  top: 55px;
  right: 15px;
  -webkit-mask-image: url("star-2.ac5f89b6.svg");
  mask-image: url("star-2.ac5f89b6.svg");
}

.zFMoKW_sparkleClusterSketch {
  width: 100px;
  height: 100px;
  top: -55px;
  right: -55px;
}

@media (max-width: 480px) {
  .zFMoKW_sparkleClusterSketch {
    width: 50px;
    height: 50px;
    top: -25px;
    right: 10px;
  }
}

.u6yA2q_backToHomePageButton {
  margin-top: 120px;
  margin-bottom: 60px;
}

._2xW6ya_pageNotFoundContainer {
  min-height: 100vh;
  margin: 0;
}

/*# sourceMappingURL=index.258f596f.css.map */
