@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --primary-dark: #242424;
  --primary-light: #f2e9e4;
  --sketch-light: #7f797b;
  --sketch-dark: #7f797b;
  --text-bg: #c6bbbe;
  --danger: #c0353c;
  --padding-xs: 5px;
  --padding-sm: 10px;
  --padding-md: 25px;
  --padding-lg: 50px;
  --padding-xl: 100px;
  --margin-sm: 10px;
  --margin-md: 20px;
  --margin-lg: 50px;
  --margin-xl: 80px;
  --gap-xs: 5px;
  --gap-sm: 10px;
  --gap-md: 25px;
  --gap-lg: 50px;
  --border-radius: 5px;
  --border-radius-accent: 50px;
  --container-max-width: 1200px;
  --content-max-width: 900px;
  --sketch-animation-duration: 0.7s;
  --font-primary: "Abril Fatface";
  --font-secondary: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}

::selection {
  color: var(--primary-light);
  background: var(--primary-dark);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

br {
  content: "";
  display: block;
  margin: 15px 0;
}

svg {
  fill: currentColor;
}
