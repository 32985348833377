.animatedAlienShipSketch {
  width: 150px;
  height: 150px;
  top: -65px;
  right: -90px;
  background-color: var(--sketch-dark);
  -webkit-mask-image: url("../../../assets/sketches/alien-ship-1.svg");
  mask-image: url("../../../assets/sketches/alien-ship-1.svg");
  animation-name: animateAlienShip;
}

@keyframes animateAlienShip {
  0% {
    -webkit-mask-image: url("../../../assets/sketches/alien-ship-1.svg");
    mask-image: url("../../../assets/sketches/alien-ship-1.svg");
  }
  33% {
    -webkit-mask-image: url("../../../assets/sketches/alien-ship-2.svg");
    mask-image: url("../../../assets/sketches/alien-ship-2.svg");
  }
  66% {
    -webkit-mask-image: url("../../../assets/sketches/alien-ship-3.svg");
    mask-image: url("../../../assets/sketches/alien-ship-3.svg");
  }
  100% {
    -webkit-mask-image: url("../../../assets/sketches/alien-ship-1.svg");
    mask-image: url("../../../assets/sketches/alien-ship-1.svg");
  }
}

@media (max-width: 480px) {
  .animatedAlienShipSketch {
    top: -95px;
    right: -90px;
  }
}
