.animatedSparkleCluster {
  animation-name: sparkle;
  background-color: var(--sketch-dark);
  -webkit-mask-image: url("../../../assets/sketches/sparkle-cluster-1.svg");
  mask-image: url("../../../assets/sketches/sparkle-cluster-1.svg");
}

@keyframes sparkle {
  0% {
    -webkit-mask-image: url("../../../assets/sketches/sparkle-cluster-1.svg");
    mask-image: url("../../../assets/sketches/sparkle-cluster-1.svg");
  }
  33% {
    -webkit-mask-image: url("../../../assets/sketches/sparkle-cluster-2.svg");
    mask-image: url("../../../assets/sketches/sparkle-cluster-2.svg");
  }
  66% {
    -webkit-mask-image: url("../../../assets/sketches/sparkle-cluster-3.svg");
    mask-image: url("../../../assets/sketches/sparkle-cluster-3.svg");
  }
  100% {
    -webkit-mask-image: url("../../../assets/sketches/sparkle-cluster-1.svg");
    mask-image: url("../../../assets/sketches/sparkle-cluster-1.svg");
  }
}
